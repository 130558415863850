<template>
  <div class="row justify-content-center">
    <div class="col-md-5">
      <v-layout row>
        <v-flex
          md6
          class="mt-5"
          offset-sm3
        >
          <v-card
            elevation="2"
            class="pa-md-4 pb-3 ma-5 mx-lg-auto"
          >
            <v-card-title class="text-h6">
              Become verified now!
            </v-card-title>

            <v-card-text class="text-body-1">
              As a verified user, you will be allowed to Join a private Telegram Group and share your Wife with other Couples which are living the same Lifestyle.
            </v-card-text>

            <v-card-title class="text-h6">
              How to get verified?
            </v-card-title>

            <v-card-text class="text-body-1">
              Please upload a photo of you in which you and your Wife, Girlfriend or Fiance are holding a paper where you have written the code and date bellow.
              After Submitting you will need to check your Email and click the Telegram Groupe Invite Button.
              When your Verification is approved your Join Request for the private Groupe will activated.
            </v-card-text>

            <v-sheet
              color="grey lighten-1"
              class="pa-md-2 ma-3 text-h7"
              height="120"
              rounded="xl"
              align="center"
            >
              <div>
                <br>
                <p>Code: &emsp;&emsp;&emsp;&emsp;&emsp; {{ codetext }}</p>
                <p>Date: &emsp;&emsp;&emsp;&emsp;&emsp; {{ datetext }}</p>
              </div>
            </v-sheet>

            <v-img
              id="rounded-img"
              class="pa-md-4 ma-5"
              :src="require('/src/assets/exemplu.jpg')"
              contain
            />

            <div>
              <picture-input
                ref="pictureInput"
                radius="10"
                margin="20"
                accept="image/jpeg,image/png"
                z-index="0"
                :custom-strings="{
                  drag: 'Take a Picture for your Verification'
                }"
                @change="onChange"
              />
            </div>

            <form
              ref="formsubmit"
              class="pa-md-4 ma-5 mt-5 mx-lg-auto"
              @submit.prevent="onFormSubmit"
            >
              <v-text-field
                v-model="user.email"
                label="E-mail"
                :rules="emailRules"
                required
              />
              <v-text-field
                v-model="user.telegramname"
                label="Telegram Username"
                required
              />
              <VuePhoneNumberInput
                v-model="user.phone"
                label="Phone"
                required
                class="mt-3"
              />

              <v-btn
                block
                class="pa-6 mt-6 white--text"
                color="red"
                @click="onFormSubmit"
              >
                submit for verification
              </v-btn>
            </form>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <v-overlay
      :value="overlay"
      z-index="1"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>
<script>

import { db, storage } from '../plugins/firebase'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { collection, addDoc } from 'firebase/firestore'
import PictureInput from 'vue-picture-input'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  components: {
    PictureInput,
    VuePhoneNumberInput
  },
  data () {
    return {
      user: {
      },
      image: null,
      pictureInput: null,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      overlay: false,
      codetext: null,
      datetext: null
    }
  },
  created () {
    this.validationcode()
  },
  methods: {
    validationcode () {
      const date = new Date().toLocaleDateString('en', { year: 'numeric', day: '2-digit', month: '2-digit' })
      const code = Math.floor(100000000 + Math.random() * 900000000)
      this.datetext = date
      this.codetext = code
    },
    onChange (image) {
      if (image) {
        this.image = image
      } else {
        console.log('Something went wrong')
      }
    },
    onFormSubmit () {
      /** @type {any} */
      const metadata = {
        contentType: 'image/jpeg'
      }

      this.overlay = true

      const storageRef = ref(storage, 'images/' + this.$refs.pictureInput.file.name)
      const uploadTask = uploadBytesResumable(storageRef, this.$refs.pictureInput.file, metadata)

      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            console.log('Upload is running')
            break
        }
      },
      (error) => {
        switch (error.code) {
          case 'storage/unauthorized':
            break

          case 'storage/canceled':
            break

          case 'storage/unknown':
            break
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            // console.log('File available at', downloadURL)
            return downloadURL
          }).then((downloadURL) => {
            // console.log('Data', downloadURL)

            addDoc(collection(db, 'verification'), {
              email: this.user.email,
              phone: this.user.phone,
              telegram: this.user.telegramname,
              image: downloadURL,
              code: this.codetext,
              date: this.datetext
            })
              .then(() => {
                this.overlay = false
                this.$refs.formsubmit.reset()
                this.$refs.pictureInput.removeImage()
                this.user.phone = ''
              })
          })
      }
      )
    }
  }
}

</script>
<style>

#rounded-img {
  border-radius: 10%;
}

p {
  font-size: 20px;
}

</style>
