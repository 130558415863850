import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { getDatabase } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBsH2BFIUayaJAM-SPa3vYQ9tEnoZs-k0U',
  authDomain: 'verification-3b457.firebaseapp.com',
  projectId: 'verification-3b457',
  storageBucket: 'verification-3b457.appspot.com',
  messagingSenderId: '1035262010232',
  appId: '1:1035262010232:web:11c113c263f481bfdbde8d'
}

const app = initializeApp(firebaseConfig)

export const database = getDatabase(app)
export const storage = getStorage(app)
export const db = getFirestore(app)
