<template>
  <v-app>
    <v-app-bar
      app
      absolute
      height="180"
    >
      <div class="flex text-center">
        <v-img
          alt="Wifessharing Logo"
          src="./assets/vre.png"
          contain
          max-height="400"
        />
      </div>
    </v-app-bar>

    <v-main>
      <Upload />
    </v-main>
  </v-app>
</template>

<script>

import Upload from './components/Upload.vue'
export default {
  name: 'App',

  components: {
    Upload
  },

  data: () => ({
  })
}

</script>
